import React from 'react';
import { Typography, Stack } from '@mui/material';
import { type } from 'os';
import Box from '@mui/material/Box';
import { Player } from '../../models/playerModels'
import { formatPrice } from '../../utility/formatting';

type PlayerCardProps = {
    player: Player;
    index: string;
}

const PlayerCard = ({ player, index }: PlayerCardProps) => {
    const image_id = player.photo.split('.')[0];
    const image = `https://resources.premierleague.com/premierleague/photos/players/110x140/p${image_id}.png`;
    return (
        <Stack direction={'column'}>
            <Box
                component="img"
                src={image}
                sx={{ maxWidth: '150px' }}
            />
            <Typography variant="body1" className="label">
                {player.web_name}
            </Typography>
            <Typography variant="body2" className="label">
                Price: {formatPrice(player.now_cost)}
            </Typography>
            <Typography variant="body2" className="label">
                Total_points: {player.total_points}
            </Typography>
            <Typography variant="body2" className="label">
                Utility {player.utility}
            </Typography>
        </Stack >
    );
};

export default PlayerCard;

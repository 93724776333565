import React, { useEffect, useState } from 'react';
import { Table, Stack, TableContainer, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { Player } from '../../models/playerModels'
import { Grid } from '@mui/material';
import { ButtonBase } from '@mui/material';
import PlayerRow from './playerRow';

type PlayerTableProps = {
    players: Array<Player>;
    rowAction?: (player: number) => void;
    interactive?: boolean;
}

const XS_SPAN = 4;
const SM_SPAN = 4;
const MD_SPAN = 2;

const PlayerTable = ({ players, rowAction = () => { }, interactive = false }: PlayerTableProps) => {

    if (players === undefined) {
        return null;
    }

    return (
        <TableContainer>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Goalkeepers</TableCell>
                        <TableCell align="right">Name</TableCell>
                        <TableCell align="right">Total points</TableCell>
                        <TableCell align="right">Utility</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">Clean sheets</TableCell>
                        <TableCell align="right">Saves</TableCell>
                        <TableCell align="right">Bonus points</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {players.filter((element) => element.element_type === 1).map((player, index) => (
                        <PlayerRow
                            onClickFunc={() => rowAction(player.id)}
                            player={player}
                            index={index.toString() + "1"}
                            interactive={interactive}
                            key={index.toString() + "1"}
                        />))}
                </TableBody>
                <TableHead>
                    <TableRow>
                        <TableCell>Defenders</TableCell>
                        <TableCell align="right">Name</TableCell>
                        <TableCell align="right">Total points</TableCell>
                        <TableCell align="right">Utility</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">Clean sheets</TableCell>
                        <TableCell align="right">Goals</TableCell>
                        <TableCell align="right">Bonus points</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {players.filter((element) => element.element_type === 2).map((player, index) => (
                        <PlayerRow
                            onClickFunc={() => rowAction(player.id)}
                            player={player}
                            index={index.toString() + "2"}
                            interactive={interactive}
                            key={index.toString() + "2"}
                        />))}
                </TableBody>
                <TableHead>
                    <TableRow>
                        <TableCell>Midfielders</TableCell>
                        <TableCell align="right">Name</TableCell>
                        <TableCell align="right">Total points</TableCell>
                        <TableCell align="right">Utility</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">Assists</TableCell>
                        <TableCell align="right">Goals</TableCell>
                        <TableCell align="right">Bonus points</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {players.filter((element) => element.element_type === 3).map((player, index) => (
                        <PlayerRow
                            onClickFunc={() => rowAction(player.id)}
                            player={player}
                            index={index.toString() + "3"}
                            interactive={interactive}
                            key={index.toString() + "3"}
                        />))}
                </TableBody>
                <TableHead>
                    <TableRow>
                        <TableCell>Strikers</TableCell>
                        <TableCell align="right">Name</TableCell>
                        <TableCell align="right">Total points</TableCell>
                        <TableCell align="right">Utility</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">Assists</TableCell>
                        <TableCell align="right">Goals</TableCell>
                        <TableCell align="right">Bonus points</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {players.filter((element) => element.element_type === 4).map((player, index) => (
                        <PlayerRow
                            onClickFunc={() => rowAction(player.id)}
                            player={player}
                            index={index.toString() + "4"}
                            interactive={interactive}
                            key={index.toString() + "4"}
                        />))}
                </TableBody>
            </Table>
        </TableContainer>



    );
}

export default PlayerTable;
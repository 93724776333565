import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from '@mui/material';
import { useBaseInfoContext } from './../utility/baseInfoContext';

import { useTheme } from '@mui/material/styles';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

import banner from './../images/small_banner.png'
import { ColorModeContext } from '../App';

const drawerWidth = 240;
const navItems = ['Home', 'All players', 'Leagues', 'Your Stats', 'About'];

interface NavBarProps {
    setCurrentPage: (page: string) => void;
    logOut: () => void;
    userId: string;
}

export const DrawerAppBar: React.FC<NavBarProps> = ({ logOut, setCurrentPage, userId }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const colorMode = React.useContext(ColorModeContext);

    const theme = useTheme();

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                Fantasy Buddy
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item} onClick={() => setCurrentPage(item)} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItem key={"fpl"} disablePadding>
                    <ListItemButton sx={{ justifyContent: 'center' }}>
                        <Link
                            href='https://fantasy.premierleague.com'
                            target="_blank"
                            color="inherit"
                            underline='none'>
                            FPL site
                        </Link>
                    </ListItemButton>
                </ListItem>

                <ListItem key={"logout"} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }}>
                        <ListItemText primary={"Log out"} onClick={() => logOut()} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"mode"} disablePadding >
                    <ListItemButton sx={{ justifyContent: 'center' }}>
                        <IconButton onClick={() => colorMode.toggleColorMode()}>
                            {theme.palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
                        </IconButton>

                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" >

                <Box sx={{ position: "relative", width: '100%' }}>
                    <Box
                        component="img"
                        sx={{ width: '100%', maxHeight: '100px', objectFit: 'cover', objectPosition: 'top' }}
                        src={banner}
                    />
                </Box>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: 'block' }}
                    >
                        Fantasy Buddy
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button key={item} sx={{ color: '#fff' }} onClick={() => setCurrentPage(item)}>
                                {item}
                            </Button>
                        ))}
                        <Button sx={{ color: '#fff' }}>
                            <Link
                                href='https://fantasy.premierleague.com'
                                target="_blank"
                                color="inherit"
                                underline='none'>
                                FPL site
                            </Link>
                        </Button>
                        <Button sx={{ color: '#fff' }} onClick={() => logOut()}>
                            Log out
                        </Button>
                        <IconButton onClick={() => colorMode.toggleColorMode()} >
                            {theme.palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
                        </IconButton>
                    </Box>

                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>

        </Box >
    );
}
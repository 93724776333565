import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import banner from './../images/small_banner.png'

import { LoginDialog } from './loginDialog';
import { getUserData, getBaseInfo, getUserTeam, getEventStatus, getAllPlayers } from '../utility/fantasyBuddyApi';
import { addUtilityToAllPlayers, getUserPlayers } from './../utility/getUserPlayers';
import { useBaseInfoContext } from './../utility/baseInfoContext';
import { CookiesProvider, useCookies } from "react-cookie";

import { getSuggestedPlayers } from './../utility/getSuggestedPlayers';
import { Player } from './../models/playerModels';
import { EventStatus } from './../models/eventModels';
import { getUserPicksIdArray } from './../utility/getSuggestedPlayers';
import { LoadingScreen } from './loadingScreen';

import TablePage from './pages/tablePage';
import AboutPage from './pages/aboutPage';
import AllPlayersPage from './pages/allPlayersPage';
import { DrawerAppBar } from './navButtons';
import { all } from 'axios';
import LeaguePage from './pages/leaguePage';
import StatsPage from './pages/statsPage';

function MainPage() {
    const [userId, setUserId] = useState("");
    const [userPlayers, setUserPlayers] = useState([] as any);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [suggestionsLoaded, setSuggestionsLoaded] = useState(false);
    const [suggestedPlayers, setSuggestedPlayers] = useState([] as any);
    const [eventStatus, setEventStatus] = useState<EventStatus>(null as any);
    const [loginDialogOpen, setLoginDialogOpen] = useState(true);
    const [showSelectedPlayer, setShowSelectedPlayer] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState<Player>(null as any);
    const [currentPage, setCurrentPage] = useState("Home");

    const [cookies, setCookie, removeCookie] = useCookies(["userId"]);

    const { baseInfo, setBaseInfo } = useBaseInfoContext();
    const { setUserData } = useBaseInfoContext();
    const { userPicks, setUserPicks } = useBaseInfoContext();
    const { setUserPicksIdArray } = useBaseInfoContext();
    const { setFantasyBuddyPlayers } = useBaseInfoContext();

    function openSelectedPlayer(player: number): void {
        const selectedPlayer = baseInfo.elements.find((element: Player) => element.id === player)
        setSelectedPlayer(selectedPlayer);
        setShowSelectedPlayer(true);
    }

    function logOut() {
        setUserId("");
        removeCookie('userId', { path: '/' });
        window.location.reload();
    }

    useEffect(() => {
        const fetchData = async () => {
            const baseInfo = await getBaseInfo();
            setBaseInfo(baseInfo);

            const eventStatus = await getEventStatus();
            setEventStatus(eventStatus);
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (cookies.userId && cookies.userId !== "") {
            setLoginDialogOpen(false)
            setUserId(cookies.userId);
        } else {
            setLoginDialogOpen(true)
        }
    }, [userId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (userId && eventStatus) {

                    setCookie("userId", userId, { path: "/" });
                    const userData = await getUserData(userId);
                    setUserData(userData);

                    const userPicks = await getUserTeam(userId, eventStatus.status[0].event);
                    setUserPicks(userPicks);

                    const fantasyBuddyPlayers = await getAllPlayers();
                    setFantasyBuddyPlayers(fantasyBuddyPlayers);

                    let newBaseInfo = baseInfo;
                    newBaseInfo.elements = addUtilityToAllPlayers(baseInfo.elements, fantasyBuddyPlayers);
                    setBaseInfo(newBaseInfo);

                    setDataLoaded(true);
                    setLoginDialogOpen(false);

                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [userId, eventStatus]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (dataLoaded) {
                    const userPlayers = getUserPlayers(baseInfo.elements, userPicks.picks);
                    setUserPlayers(userPlayers);
                    const suggestedPlayers = await getSuggestedPlayers(baseInfo.elements, userPicks.picks);
                    setSuggestedPlayers(suggestedPlayers);
                    setUserPicksIdArray(getUserPicksIdArray(userPicks.picks))

                    setSuggestionsLoaded(true);
                }
                // This will be executed after the asynchronous operations are complete

            } catch (error) {
                // Handle errors
                console.error('Error:', error);
            }
        };

        fetchData(); // Invoke the async function

    }, [dataLoaded]);

    let page = <></>;
    switch (currentPage) {
        case "Home":
            page = (
                <TablePage
                    userPlayers={userPlayers}
                    suggestedPlayers={suggestedPlayers}
                    openSelectedPlayer={openSelectedPlayer}
                    showSelectedPlayer={showSelectedPlayer}
                    setShowSelectedPlayer={setShowSelectedPlayer}
                    selectedPlayer={selectedPlayer}
                />
            );
            break;
        case "All players":
            page = (
                <AllPlayersPage
                    players={baseInfo.elements}
                />
            );
            break;
        case "About":
            page = (
                <AboutPage />
            );
            break;
        case "Leagues":
            page = (
                <LeaguePage />
            );
            break;
        case "Your Stats":
            page = <StatsPage />;
            break;
        default:
            console.log("default");
            break;
    }

    return (
        <>
            <CssBaseline />


            <DrawerAppBar logOut={logOut} setCurrentPage={setCurrentPage} userId={userId} />

            <LoginDialog
                open={loginDialogOpen}
                setUserId={setUserId}
            />

            <LoadingScreen open={!suggestionsLoaded} />

            <Box component="main" sx={{ p: 3 }}>
                <Box
                    component="img"
                    sx={{ width: '100%', maxHeight: '100px', opacity: 0 }}
                    src={banner}
                />
                <Toolbar />
                {suggestionsLoaded ? page : <></>}
            </Box>


        </>

    );
}

export default MainPage;

import { Player, FantasyBuddyPlayer, UserPlayer } from '../models/playerModels'
import { getPlayers, getPlayerSuggestions } from './fantasyBuddyApi';

const playerCount = [2, 5, 5, 3];

export async function getSuggestedPlayers(allPlayers: Array<Player>, usersPlayers: Array<UserPlayer>) {
    let suggestions = [] as any
    for (let i = 1; i < 5; i++) {
        const bestInPosition: Array<FantasyBuddyPlayer> = await getPlayers(10, i);
        const filteredBestInPosition = bestInPosition.filter((suggestedPlayer) => {
            // Check if the player already exists in the team
            const existsInTeam = usersPlayers.some((player) => player.element === suggestedPlayer.id);

            return !existsInTeam;
        });
        const players = filteredBestInPosition.slice(0, playerCount[i - 1]).map((player) => {
            const userPlayer = allPlayers.find((element) => element.id === player.id)
            return {
                ...player,
                ...userPlayer,
            };
        });
        suggestions = suggestions.concat(players);
    }
    return suggestions;
}

export async function getSuggestedPlayersForPlayer(player: Player, allPlayers: Array<Player>, moneyInBank: number, userPicksIdArray: Array<number>) {
    let suggestions = await getPlayerSuggestions(player.now_cost + moneyInBank, player.element_type, userPicksIdArray);

    let result = (suggestions).map((suggestedPlayer) => {
        const userPlayer = allPlayers.find((element) => element.id === suggestedPlayer.id)
        return {
            ...suggestedPlayer,
            ...userPlayer,
        };
    })

    return result;
}

export function getUserPicksIdArray(userPicks: Array<UserPlayer>) {
    const userPicksIdArray = userPicks.map((element) => element.element);
    return userPicksIdArray;
}
import axios from 'axios';
import { Player, FantasyBuddyPlayer, UserPlayer } from '../models/playerModels'
import { EventStatus } from '../models/eventModels';
import LeagueData from '../models/leagueModels';

export async function getUserData(userId: string): Promise<object> {
    const url = `https://fantasy-buddy-service.onrender.com/user`;
    try {
        const response = await axios.get(url, { params: { userId: userId } });
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        return {};
    }
}

export async function getPlayers(limit: number, position: number): Promise<Array<FantasyBuddyPlayer>> {
    const url = `https://fantasy-buddy-service.onrender.com/players?numPlayers=${limit}&position=${position}`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export async function getUserTeam(userId: string, event_id: number): Promise<object> {
    const url = `https://fantasy-buddy-service.onrender.com/userTeam?userId=${userId}&eventId=${event_id}`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        return {};
    }
}

export async function getPlayersPriceLimit(price: number, position: number): Promise<Array<FantasyBuddyPlayer>> {
    const url = `https://fantasy-buddy-service.onrender.com/players?price=${price}&position=${position}`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export async function getPlayerSuggestions(price: number, position: number, id: Array<number>): Promise<Array<FantasyBuddyPlayer>> {
    const url = `https://fantasy-buddy-service.onrender.com/playerSuggestions?price=${price}&position=${position}&id=[${id}]`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export async function getBaseInfo(): Promise<object> {
    const url = `https://fantasy-buddy-service.onrender.com/bootstrapData`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        return {};
    }
}

export async function getEventStatus(): Promise<EventStatus> {
    const url = `https://fantasy-buddy-service.onrender.com/eventStatus`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function getAllPlayers(): Promise<Array<FantasyBuddyPlayer>> {
    const url = `https://fantasy-buddy-service.onrender.com/playersAll`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function getLeagueStandings(leagueId: Array<Number>): Promise<Array<LeagueData>> {
    const url = `https://fantasy-buddy-service.onrender.com/league?leagueId=[${leagueId}]`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}
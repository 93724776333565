import React, { useEffect } from 'react';
import { Player } from '../../models/userDataModels';
import { Box, Typography } from '@mui/material';
import { useBaseInfoContext } from '../../utility/baseInfoContext';
import { formatPrice } from '../../utility/formatting';

const StatsPage = () => {
    const { userData } = useBaseInfoContext();

    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="h3">
                {userData.name}
            </Typography>
            <Typography variant="body1">
                Overall Rank: {userData.summary_overall_rank}
            </Typography>
            <Typography variant="body1">
                Total points: {userData.summary_overall_points} points
            </Typography>
            <Typography variant="body1">
                GW rank: {userData.summary_event_rank}
            </Typography>
            <Typography variant="body1">
                GW points: {userData.summary_event_points} points
            </Typography>
            <Typography variant="body1">
                Team value: {formatPrice(userData.last_deadline_value)}
            </Typography>
        </Box>
    );
};

export default StatsPage;


import React from 'react';
import { Grid, Typography } from '@mui/material';
import PlayerTable from './../tableComponents/playerTable';
import { Player } from './../../models/playerModels';
import PlayerShowcase from './../players/playerShowcase';

interface TablePageProps {
    userPlayers: Player[];
    suggestedPlayers: Player[];
    openSelectedPlayer: (player: number) => void;
    showSelectedPlayer: boolean;
    setShowSelectedPlayer: (show: boolean) => void;
    selectedPlayer: Player;
}

export const TablePage: React.FC<TablePageProps> = ({ userPlayers, suggestedPlayers, openSelectedPlayer, showSelectedPlayer, setShowSelectedPlayer, selectedPlayer }) => {
    return (
        <Grid container justifyContent={"center"} spacing={3}>

            <Grid item xs={12} sm={12} md={6}>
                <Typography variant="subtitle1" gutterBottom align="center">
                    Your team
                </Typography>

                <PlayerTable players={userPlayers} rowAction={openSelectedPlayer} interactive={true} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
                {
                    showSelectedPlayer ?
                        <PlayerShowcase open={showSelectedPlayer} onClose={() => { setShowSelectedPlayer(false) }} player={selectedPlayer} />
                        :
                        <><Typography variant="subtitle1" gutterBottom align="center">
                            Suggested players
                        </Typography>
                            <PlayerTable players={suggestedPlayers} />
                        </>
                }

            </Grid>
        </Grid>
    );
};

export default TablePage;

import React, { useEffect } from 'react';
import { League } from '../../models/userDataModels';
import { Box, Typography } from '@mui/material';

interface LeagueStatsProps {
    stats: League;
}

const LeagueStats: React.FC<LeagueStatsProps> = ({ stats }) => {

    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="body1">
                Your rank is <b>{stats.entry_rank}</b>{stats.entry_rank !== 1 && `, top ${stats.entry_percentile_rank}%`}
            </Typography>
            <Typography variant="body1">
                Your rank last GW was {stats.entry_last_rank}
            </Typography>
            <Typography variant="body1">
                Players in league: {stats.rank_count}
            </Typography>

        </Box>
    );
};

export default LeagueStats;

import React, { useEffect } from 'react';
import { Button, Grid, Typography, Stack, Box } from '@mui/material';
import { Player } from '../../models/playerModels';
import { formatPrice } from '../../utility/formatting';
import { getSuggestedPlayersForPlayer } from '../../utility/getSuggestedPlayers';
import { useBaseInfoContext } from '../../utility/baseInfoContext';
import PlayerCard from './playerCard';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface PlayerShowcaseProps {
    open: boolean;
    onClose: () => void;
    player: Player;
}

const PlayerShowcase: React.FC<PlayerShowcaseProps> = ({ open, onClose, player }) => {
    const { baseInfo } = useBaseInfoContext();
    const { userPicks } = useBaseInfoContext();
    const { userPicksIdArray } = useBaseInfoContext();
    const [suggestions, setSuggestions] = React.useState([] as any);
    const theme = useTheme();
    const isSmallViewport = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (player === null) {
            return;
        }
        const fetchData = async () => {
            const suggestions = await getSuggestedPlayersForPlayer(player, baseInfo.elements, userPicks.entry_history.bank, userPicksIdArray);
            setSuggestions(suggestions);
        };
        //Scroll to bottom on small viewports
        if (isSmallViewport) {
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            window.scrollTo(0, 0);
        }

        fetchData(); // Invoke the async function
    }, [player]);

    if (player === null) {
        return null;
    }

    const image_id = player.photo.split('.')[0];
    const image = `https://resources.premierleague.com/premierleague/photos/players/110x140/p${image_id}.png`;

    return (
        <Grid container spacing={3} padding={3}>
            <Grid item xs={12} sm={12} md={12} alignItems={"center"}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="h5">
                        {player.web_name}
                    </Typography>
                    <Button onClick={onClose} color="primary" variant='outlined'>
                        Close
                    </Button>
                </Stack>

            </Grid>
            <Grid item xs={12} sm={12} md={6} alignItems={"center"}>
                <Box
                    component="img"
                    sx={{ width: '150px' }}
                    src={image}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={6} alignItems={"center"}>

                <Typography variant="body1">Price: {formatPrice(player.now_cost)}</Typography>
                <Typography variant="body1">Total points: {player.total_points}</Typography>
                <Typography variant="body1">Utility: {player.utility}</Typography>
                <Typography variant="body1">Bonus: {player.bonus}</Typography>
                <Typography variant="body1">Goals: {player.goals_scored}</Typography>
                <Typography variant="body1">Assists: {player.assists}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} alignItems={"center"}>
                <Typography variant="h6">Suggested replacements</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}  >
                {
                    suggestions.length > 0 ?
                        <Grid container alignItems={"space-around"}>

                            <Grid item xs={12} sm={6} md={6} alignItems={"center"}>
                                <PlayerCard player={suggestions[0]} index={"0"} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} alignItems={"center"}>
                                <PlayerCard player={suggestions[1]} index={"1"} />
                            </Grid>
                        </Grid>
                        : null
                }
            </Grid>
        </Grid>
    );
};

export default PlayerShowcase;

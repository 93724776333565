import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';

interface FormDialogrops {
  open: boolean;
  setUserId: (userId: string) => void;
}

export const LoginDialog: React.FC<FormDialogrops> = ({ open, setUserId }) => {
  const handleClose = () => {
    setUserId(userIdTextField);
  };

  const [usernameTextField, setUsernameTextField] = useState('');
  const [userIdTextField, setUserIdTextField] = useState('');
  const [passwordTextField, setPasswordTextField] = useState('');

  const handleSetUserIdTextFieldChange = (event: any) => {
    setUserIdTextField(event.target.value);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Provide user info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To use Fantasy Buddy, you must authenticate yourself with your premier league user id.
            You also need to provide your userId, that you can find following <Link href='https://fpl.team/find-id' target="_blank">these steps</Link>.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="userId"
            label="userId"
            type="standard"
            fullWidth
            variant="standard"
            value={userIdTextField}
            onChange={handleSetUserIdTextFieldChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Login</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
import React from 'react';
import { Typography, TableRow, TableCell } from '@mui/material';
import { type } from 'os';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Player } from '../../models/playerModels'
import { formatPrice } from '../../utility/formatting';

type PlayerRowProps = {
    player: Player;
    index: string;
    onClickFunc: (player: number) => void;
    interactive: boolean;
}

const PlayerRow = ({ player, index, onClickFunc, interactive }: PlayerRowProps) => {
    const image_id = player.photo.split('.')[0];
    const image = `https://resources.premierleague.com/premierleague/photos/players/110x140/p${image_id}.png`;
    return (
        <TableRow onClick={() => onClickFunc(player.id)} sx={{ cursor: interactive ? 'pointer' : '' }}>
            <TableCell>
                <Box
                    component="img"
                    sx={{ width: '50px' }}
                    src={image}
                />
            </TableCell>
            <TableCell align="right">{player.web_name}</TableCell>
            <TableCell align="right">{player.total_points}</TableCell>
            <TableCell align="right">{player.utility}</TableCell>
            <TableCell align="right">{formatPrice(player.now_cost)}</TableCell>

            {(player.element_type === 1 || player.element_type === 2) ?
                <TableCell align="right">{player.clean_sheets}</TableCell>
                : ""
            }
            {player.element_type === 1 ?
                <TableCell align="right">{player.saves}</TableCell>
                : ""
            }
            {(player.element_type === 3 || player.element_type === 4) ?
                <TableCell align="right">{player.assists}</TableCell>
                : ""
            }
            {player.element_type !== 1 ?
                <TableCell align="right">{player.goals_scored}</TableCell>
                : ""
            }
            <TableCell align="right">{player.bonus}</TableCell>

        </TableRow >
    );
};

export default PlayerRow;

import { Player, UserPlayer, FantasyBuddyPlayer } from '../models/playerModels'

export function getUserPlayers(allPlayers: Array<Player>, usersPlayers: Array<UserPlayer>) {
    const players = usersPlayers.map((player) => addDataToUserPlayers(player, allPlayers));
    return players;
}

function addDataToUserPlayers(userPlayer: UserPlayer, allPlayers: Array<Player>) {
    const allPlayer = allPlayers.find((element) => element.id === userPlayer.element)
    return {
        ...userPlayer,
        ...allPlayer,
    };
}

export function addUtilityToAllPlayers(allPlayers: Array<Player>, fantasyBuddyPlayers: Array<FantasyBuddyPlayer>): any {
    const players = allPlayers.map((player) => {
        const utility = getUtility(player.id, fantasyBuddyPlayers);
        return {
            ...player,
            "utility": utility,
        };
    });
    return players;
}

export function getUtility(playerId: number, fantasyBuddyPlayers: Array<FantasyBuddyPlayer>): number {
    const fantasyBuddyPlayer = fantasyBuddyPlayers.find((element) => element.id === playerId)
    if (fantasyBuddyPlayer === undefined) {
        return 0;
    }
    return fantasyBuddyPlayer.utility;
}
import { PropsWithChildren, createContext, useContext, useState } from 'react';

type ContextType = {
    baseInfo: any;
    setBaseInfo: (baseInfo: {}) => void;
    userData: any;
    setUserData: (userInfo: {}) => void;
    userPicks: any;
    setUserPicks: (userInfo: {}) => void;
    userPicksIdArray: any;
    setUserPicksIdArray: (userPicksIdArray: {}) => void;
    fantasyBuddyPlayers: any;
    setFantasyBuddyPlayers: (userPicksIdArray: {}) => void;
};
export const BaseInfoContext = createContext<ContextType | undefined>(undefined);

export const BaseinfoProvider = ({ children }: PropsWithChildren<{}>) => {
    const [baseInfo, setBaseInfo] = useState({} as any);
    const [userData, setUserData] = useState({} as any);
    const [userPicks, setUserPicks] = useState([] as any);
    const [fantasyBuddyPlayers, setFantasyBuddyPlayers] = useState([] as any);
    const [userPicksIdArray, setUserPicksIdArray] = useState([] as any);

    return (
        <BaseInfoContext.Provider value={{ baseInfo, setBaseInfo, userData, setUserData, userPicks, setUserPicks, userPicksIdArray, setUserPicksIdArray, fantasyBuddyPlayers, setFantasyBuddyPlayers }}>
            {children}
        </BaseInfoContext.Provider>
    );
};

export const useBaseInfoContext = () => {
    const context = useContext(BaseInfoContext);

    if (!context) {
        throw new Error('useThemeContext must be used inside the ThemeProvider');
    }

    return context;
};

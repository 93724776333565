import React, { useEffect } from 'react';
import { useBaseInfoContext } from '../../utility/baseInfoContext';
import { Grid, List, ListItem, ListItemText, Typography, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getLeagueStandings } from '../../utility/fantasyBuddyApi';
import LeagueTable from '../leagueComponents/leagueTable';
import LeagueData from '../../models/leagueModels';
import { Standing } from '../../models/leagueModels';
import { League } from '../../models/userDataModels';
import LeagueStats from '../leagueComponents/leagueStats';

const LeaguePage = () => {
    const { userData } = useBaseInfoContext();
    const [selectedLeague, setSelectedLeague] = React.useState(userData.leagues.classic[0].id);
    const [standings, setStandings] = React.useState<Array<Standing>>(null as any);
    const [leagueStats, setLeagueStats] = React.useState<League>(null as any); // [leagueId, leagueData
    const [userLeagueStats, setUserLeagueStats] = React.useState<League>(null as any); // [leagueId, leagueData
    const [leagueData, setLeagueData] = React.useState<Array<LeagueData>>(null as any);

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedLeague(event.target.value);
    };

    useEffect(() => {
        if (leagueData === null) {
            return;
        }
        const selectedLeagueData = leagueData.find((element: any) => element.league.id === selectedLeague);
        const selectedLeagueStats = userData.leagues.classic.find((element: any) => element.id === selectedLeague);
        if (selectedLeagueStats === undefined || selectedLeagueData === undefined) {
            return;
        }
        setStandings(selectedLeagueData.standings.results);
        setLeagueStats(selectedLeagueStats);
    }, [selectedLeague, leagueData]);

    useEffect(() => {
        const fetchData = async () => {
            const leagueId = userData.leagues.classic.map((league: any) => league.id);
            const leagueData = await getLeagueStandings(leagueId);
            setLeagueData(leagueData);
        }

        fetchData();
    }, []);

    return (
        <Grid container spacing={3}>

            <Grid item xs={12} sm={12} md={12} alignItems={"start"}>

                <Select
                    value={selectedLeague}
                    onChange={handleChange}
                >
                    {userData.leagues.classic.map((league: any) => (
                        <MenuItem value={league.id} key={league.id}>
                            {league.name}
                        </MenuItem>
                    ))}
                </Select>

            </Grid>

            <Grid item xs={12} sm={12} md={9} alignItems={"center"}>

                {standings === null ? null : <LeagueTable players={standings} />}

            </Grid>

            <Grid item xs={12} sm={12} md={3} alignItems={"center"}>

                {leagueStats === null ? null : <LeagueStats stats={leagueStats} />}

            </Grid>





        </Grid>

    );
};

export default LeaguePage;

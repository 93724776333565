import React, { createContext } from 'react';
import { BaseinfoProvider } from './utility/baseInfoContext';

import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { green, orange, purple } from '@mui/material/colors';
import { ThemeProvider } from '@mui/material/styles';
import MainPage from './components/mainPage';

export const ColorModeContext = createContext({ toggleColorMode: () => { } });

function App() {
  const [mode, setMode] = React.useState<'light' | 'dark'>(window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  let theme = createTheme({
    palette: {
      mode,
      ...(mode === 'light' ? {
        primary: {
          main: green[700],
        },
        secondary: {
          main: purple[500],
        },
      } : {
        primary: {
          main: green[200],
        },
        secondary: {
          main: purple[200],
        },
      })

    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <BaseinfoProvider>
          <MainPage />


        </BaseinfoProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>


  );
}

export default App;
